require("masonry-layout");
require("jquery");
require("bootstrap")
import "popper.js";


const amenitites = [
  {
    name: "24Hr Security",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534707501/iStock-524578227-1024x542_xijqcg.jpg"
  },
  {
    name: "Allotted Car Parks",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534706858/park_tikykj.jpg"
  },
  {
    name: "Police Post",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534707778/police_zmw5jp.jpg"
  },
  {
    name: "Clinic",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534707423/clinic-location-weybridge-the-private-clinic_qypawb.jpg"
  },
  {
    name: "School",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534707779/school_eh86zn.jpg"
  },
  {
    name: "Water & Electricity backup",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534707777/gen_taokjd.jpg"
  },
  {
    name: "Convenience Store",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534706861/store_zobxkb.jpg"
  },
  {
    name: "Swimming Pool",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534707430/2014-Swimming-Pool_sdrqxp.jpg"
  },
  {
    name: "Cafeteria",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534707501/palais-des-nations-cafeteria_svl9t8.jpg"
  },
  {
    name: "Basketball & Tennis Court",
    image: "https://res.cloudinary.com/claeusdev/image/upload/v1534706857/tennis_g8ssq1.jpg"
  },
  {
    name: "Club house"
  }
];

const amenitySection = amenitites.map(amenity => (
    `<ul class="amenity col-md-4">
        <li class="amenity-name">${amenity.name}</li>
    </ul>`
)).join("")

document.getElementById('amenities').innerHTML = amenitySection

document.querySelectorAll('.amenity-image').forEach(image => image.setAttribute('style', "display: none; width: 350px; cursor: pointer;"))

document.querySelectorAll('.amenity-name').forEach(name => name.onmouseover = () => {
    name.parentElement.classList.add('show-image')

    if (name.parentElement.classList.contains('show-image')) {
        name.parentElement.children[1].setAttribute("style", "display: block; transition: 0.2s ease; width: 350px;");
    }
})

document.querySelectorAll('.amenity-name').forEach(name => name.onmouseout = () => {
    name.parentElement.classList.add('show-image')

    if (name.parentElement.classList.contains('show-image')) {
        name.parentElement.children[1].style.display = "none"
    }
})

